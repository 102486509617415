import { FC, PropsWithChildren } from 'react';
import { useSiteMetadata } from './use-site-metadata';

type Props = {
  title?: string;
  description?: string;
  pathname?: string;
};

export const SEO: FC<PropsWithChildren<Props>> = ({
  title,
  description,
  pathname,
  children,
}) => {
  const metadata = useSiteMetadata();

  const seo = {
    title: title || metadata?.title || '',
    description: description || metadata?.description || '',
    url: `${metadata?.siteUrl}${pathname || ''}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name='description' content={seo.description} />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter.description' content={seo.description} />
      {children}
    </>
  );
};
