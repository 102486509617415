import LogoDrawing from '@animations/LogoNeon';
import { Box, Center, Portal } from '@chakra-ui/react';
import { useLayoutAtom } from '@components/core/Layout';
import { SEO } from '@feature/seo';
import { navigate } from 'gatsby';
import { FC, useEffect } from 'react';

export const Head = () => <SEO title='Start' />;

const StartPage: FC = () => {
  const layout = useLayoutAtom();

  useEffect(() => {
    layout.hide();

    setTimeout(() => {
      const visitKey = 'visit';
      localStorage.setItem(visitKey, 'true');

      navigate('/');
    }, 5000);

    return () => {
      layout.show();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <SEO title='Start' />

      <Portal>
        <Box as='section' h='100%' w='100%' position='fixed'>
          <Center h='100%'>
            <LogoDrawing />
          </Center>
        </Box>
      </Portal>
    </>
  );
};

export default StartPage;
